<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { MyInputRef } from '@/types/inputs'
import { ColumnFilterType, ColumnFilter, InternalTableColumn } from '@/types/table'

import MyButton from '@/components/my-components/MyButton.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

export interface Props {
    column: InternalTableColumn
    filters: ColumnFilter[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'addFilter'): void
    (e: 'removeFilter', filterIndex: number): void
    (e: 'clearFilters'): void
}>()

const { t } = useI18n()

const filterTypeOptions = [
    { value: ColumnFilterType.Contains, label: t('contains') },
    { value: ColumnFilterType.NotContains, label: t('doesNotContain') },
]

const inputs = ref<MyInputRef[]>()

const hasValidFilters = computed(() => {
    if (!props.filters) return
    const validFilters = props.filters.filter((filter) => filter.value.trim().length > 0)

    return validFilters.length > 0
})

const canAddAdditionalFilter = computed(() => {
    if (props.filters.length === 0) return false

    // Check that the newest filter actually has a value
    return props.filters[props.filters.length - 1].value.trim().length >= 1
})

function filterButtonClicked() {
    if (props.filters.length > 0) return

    emit('addFilter')
}

function focusLatestInput() {
    if (!inputs.value) return
    setTimeout(() => {
        const filtersLength = inputs.value!.length
        inputs.value![filtersLength - 1].input.focus()
    }, 50)
}

watch(
    () => props.filters.length,
    () => focusLatestInput(),
)
</script>

<template>
    <VDropdown
        v-if="column.filterable"
        class="items-center"
        theme="filter-panel"
        @apply-show="focusLatestInput()"
    >
        <template #default="{ shown }">
            <span
                :class="{
                    'group-hover/item:block hidden': !hasValidFilters && !shown,
                }"
                @click.stop="filterButtonClicked"
            >
                <mdi:filter-outline v-if="!hasValidFilters" />
                <mdi:filter v-else />
            </span>
        </template>

        <template #popper>
            <div class="flex flex-col py-3 px-3 space-y-3 max-w-lg">
                <div v-for="(row, rowIndex) in props.filters" :key="rowIndex" class="flex group">
                    <MyInput
                        ref="inputs"
                        v-model="row.value"
                        :placeholder="t('filter')"
                        class="dark:text-white rounded-l-lg rounded-r-none shadow-none focus:shadow-none group-focus-within:shadow-md border-r-0"
                        group-class="border-r-2 dark:border-dark-500 border-gray-300"
                    />
                    <MySelect
                        v-model="row.type"
                        name="type"
                        wrapper-class="bg-white! rounded-l-none rouned-r-lg shadow-none group-focus-within:shadow-md border-l-0"
                        :options="filterTypeOptions"
                        :popper-container="'.v-popper__wrapper'"
                    />
                    <MyButton v-if="rowIndex > 0" icon @click="emit('removeFilter', rowIndex)">
                        <mdi:close />
                    </MyButton>
                </div>
                <div class="flex justify-between">
                    <MyButton
                        v-if="hasValidFilters"
                        class="max-w-fit"
                        scheme="warning"
                        pill
                        @click="emit('clearFilters')"
                    >
                        {{ t('resetFilters') }}
                    </MyButton>
                    <MyButton
                        v-if="canAddAdditionalFilter"
                        class="max-w-fit self-end"
                        scheme="primary"
                        plain
                        pill
                        @click="emit('addFilter')"
                    >
                        {{ t('addFilter') }}
                        <mdi:plus />
                    </MyButton>
                </div>
            </div>
        </template>
    </VDropdown>
</template>
