import type { HTMLClass } from '@/types/inputs'

import { InjectionKey, Ref, Slot } from 'vue'

import { PaginatedResponse, PaginationParameters } from '@/types/general'

export interface TableColumn {
    name: string
    property: string
    hidden?: boolean
    sortable?: boolean
    date?: string
    minWidth?: number
    width?: number
    filterable?: boolean
}

export interface InternalTableColumn extends TableColumn {
    slots?: InternalSlots
    hidden: boolean
    sortable: boolean
    order: number
    draggable?: boolean
    minWidth: number
    booleanColumn: boolean
    class?: HTMLClass
}

export interface InternalTableGroup {
    slots?: InternalSlots
    name: string
    group: string
}

export interface ColumnSize {
    width: number
    x: number
}

export interface ColumnFilter {
    value: string
    type: ColumnFilterType
}

export enum ColumnFilterType {
    Contains = 0,
    NotContains = 1,
}

// Taken from Vue - not exported
declare type InternalSlots = {
    [name: string]: Slot | undefined
}

export interface GetDataParameters extends PaginationParameters {
    query?: string
    filter?: Record<string, unknown>
    columnFilters?: string
}

export const paginatedRowsKey: InjectionKey<PaginatedResponse<Record<string, unknown>>> =
    Symbol('paginatedRows')
export const insertColumnKey: InjectionKey<(column: InternalTableColumn) => void> =
    Symbol('insertColumn')
export const updateColumnKey: InjectionKey<
    (property: string, column: Partial<InternalTableColumn>) => void
> = Symbol('updateColumn')
export const removeColumnKey: InjectionKey<(column: InternalTableColumn) => void> =
    Symbol('removeColumn')
export const organizeColumnsKey: InjectionKey<(refit?: boolean) => void> = Symbol('organizeColumns')
export const columnsKey: InjectionKey<Ref<Map<string, InternalTableColumn>>> = Symbol('columnsKey')
export const columnSizesKey: InjectionKey<Ref<Map<string, ColumnSize>>> = Symbol('columnSizes')
export const refetchKey: InjectionKey<
    ((options?: Partial<GetDataParameters>) => Promise<void>) | undefined
> = Symbol('refetch')
