<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ref, watch, nextTick, onBeforeMount } from 'vue'
import axios from 'axios'

import { FieldPreference } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { DeliveryManagementPreferences } from '@/types/delivery-management'

import Loader from '@/components/loaders/Loader.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'

let mounted = false

const authStore = useAuthStore()
const { t } = useI18n()

const preferences = ref<DeliveryManagementPreferences>({
    shipmentPodCreation: false,
    colliScan: FieldPreference.Optional,
    images: FieldPreference.Optional,
    note: FieldPreference.Optional,
    transaction: FieldPreference.Optional,
    signature: FieldPreference.Optional,
    unsuccessful: FieldPreference.Optional,
    deviation: FieldPreference.Optional,
    hideReturnShipmentColliScan: false,
})

const saving = ref(false)

async function savePreferences() {
    if (!mounted) return

    saving.value = true
    await axios.put(
        window.route('dm.company.preferences.update', { company: authStore.companyId }),
        preferences.value,
    )
    authStore.licenses[LicenseType.DeliveryManagementModule] = {
        ...authStore.licenses[LicenseType.DeliveryManagementModule],
        ...preferences.value,
    }
    saving.value = false
}

watch(preferences, savePreferences, { deep: true })

onBeforeMount(async () => {
    const prefs: Partial<DeliveryManagementPreferences> =
        authStore.licenses[LicenseType.DeliveryManagementModule] ?? {}

    preferences.value = { ...preferences.value, ...prefs }
    await nextTick()
    mounted = true
})
</script>

<template>
    <MyPanel panel-class="w-96" padded shadow>
        <Loader
            class="absolute right-6 top-6 opacity-0 transition-opacity duration-300"
            :class="{ 'opacity-100': saving }"
        />

        <div class="flex flex-col space-y-4">
            <MyRadioButtonGroup
                v-model="preferences.shipmentPodCreation"
                class="relative"
                :label="t('podCreation')"
            >
                <MyRadioButton :label="t('enabled')" :value="true" />
                <MyRadioButton :label="t('disabled')" :value="false" />

                <template #label>
                    {{ t('podCreation') }}
                    <mdi:information
                        v-tooltip="t('podCreationExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.colliScan" :label="t('colliScan')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.hideReturnShipmentColliScan"
                class="relative"
                :label="t('returnShipmentColliScan')"
            >
                <MyRadioButton :label="t('visible')" :value="false" />
                <MyRadioButton :label="t('hidden')" :value="true" />

                <template #label>
                    {{ t('returnShipmentColliScan') }}
                    <mdi:information
                        v-tooltip="t('returnShipmentColliScanExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.images" :label="t('images')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.note" :label="t('note')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.transaction"
                :label="t('packagingTransaction')"
            >
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup v-model="preferences.signature" :label="t('signature')">
                <MyRadioButton :label="t('required')" :value="FieldPreference.Required" />
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.unsuccessful"
                class="relative"
                :label="t('unsuccessful')"
            >
                <MyRadioButton :label="t('optional')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />

                <template #label>
                    {{ t('unsuccessfulShipment') }}
                    <mdi:information
                        v-tooltip="t('unsuccessfulExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>

            <MyRadioButtonGroup
                v-model="preferences.deviation"
                class="relative"
                :label="t('deviation')"
            >
                <MyRadioButton :label="t('visible')" :value="FieldPreference.Optional" />
                <MyRadioButton :label="t('hidden')" :value="FieldPreference.Hidden" />

                <template #label>
                    {{ t('deviations') }}
                    <mdi:information
                        v-tooltip="t('deviationsExplanation')"
                        class="absolute top-1 right-0"
                    />
                </template>
            </MyRadioButtonGroup>
        </div>
    </MyPanel>
</template>
