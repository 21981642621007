<script lang="ts">
interface Props {
    customerCompanyId?: uuid
}
</script>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import Datepicker, { DatePickerInstance } from '@vuepic/vue-datepicker'
import axios from 'axios'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { RouteLocationRaw, useRoute } from 'vue-router'
import dayjs from 'dayjs'

import { useCompanyStore } from '@/stores/company-store'
import { useAuthStore } from '@/stores/auth-store'
import { uuid, PaginatedResponse, MinimalResource, ResourceResponse } from '@/types/general'
import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { ShipmentType, MinimalShipment, Tour } from '@/types/delivery-management'
import { GetDataParameters } from '@/types/table'
import { datetime, localeDate, localeTime } from '@/utils/dates'
import { DropdownStringOption } from '@/types/inputs'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { useMittListener } from '@/hooks/use-mitt-listener'
import { shipmentTypes } from '@/utils/delivery-management'

import ShipmentStatus from '@/components/delivery-management/ShipmentStatus.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import MyFilterButton from '@/components/table/MyFilterButton.vue'
import ManageShipmentModal from '@/components/delivery-management/ManageShipmentModal.vue'
import HandleShipmentModal from '@/components/delivery-management/HandleShipmentModal.vue'
import MyFilterDivider from '@/components/table/MyFilterDivider.vue'
import MyFilterSelect from '@/components/table/MyFilterSelect.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'
import CMR from '@/components/delivery-management/CMR.vue'
import CreateTicketModal from '@/components/delivery-management/CreateTicketModal.vue'

interface FetchShipmentsParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        type: ShipmentType | undefined
        status: string | undefined
        'hub-id': uuid | undefined
        'tour-id': uuid | undefined
        'sending-location-id': uuid | undefined
        'customer-company-id': uuid | undefined
        'start-date': string | undefined
        'end-date': string | undefined
    }
}

const props = defineProps<Props>()
const companyStore = useCompanyStore()
const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const presetRanges = usePresetRangeList()

const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    MinimalShipment,
    FetchShipmentsParams
>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<MinimalShipment>>(
            window.route('dm.company.shipments.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    {
        filter: {
            type: undefined,
            status: undefined,
            'hub-id': undefined,
            'tour-id': undefined,
            'sending-location-id': undefined,
            'start-date': dayjs().subtract(6, 'days').startOf('day').toDate().toISOString(),
            'end-date': dayjs().endOf('day').toDate().toISOString(),
            'customer-company-id': props.customerCompanyId,
        },
    },
)
const hubs = useLazyLoadedList<DropdownStringOption>(fetchHubs)

const tour = ref<Tour>()
const shipmentModalOpen = ref(false)
const handleShipmentModalOpen = ref(false)
const cmrModalOpen = ref(false)
const selectedShipment = ref<MinimalShipment>()
const ticketModalOpen = ref(false)
const datepicker = ref<DatePickerInstance>(null)
const date = ref<Date[]>(
    params.value.filter['start-date'] && params.value.filter['end-date']
        ? [
              dayjs(params.value.filter['start-date']).toDate(),
              dayjs(params.value.filter['end-date']).toDate(),
          ]
        : [],
)

const shipmentStatuses = computed<DropdownStringOption[]>(() => [
    { value: 'unassigned', label: t('noAssignedTour') },
    { value: 'notDeliveredOrPickedUp', label: t('notDeliveredOrPickedUp') },
    { value: 'deliveredOrPickedUp', label: t('deliveredOrPickedUp') },
    { value: 'delayed', label: t('delayed') },
    { value: 'early', label: t('early') },
    { value: 'inTransit', label: t('inTransit') },
])

const locations = computed<DropdownStringOption[]>(() =>
    companyStore.locations.map(({ id, name }) => ({ value: id, label: name })),
)

async function fetchTour(id?: uuid) {
    try {
        const response = await axios.get<ResourceResponse<Tour>>(
            window.route('dm.company.tours.show', {
                company: authStore.companyId,
                tour: id,
            }),
        )
        tour.value = response.data.data
    } finally {
        loading.value = false
    }
}

async function fetchHubs(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.hubs', { company: authStore.companyId }),
    )

    return response.data.map((hub) => ({
        value: hub.id,
        label: hub.name,
    }))
}

function isSenderOrShipper(shipment: MinimalShipment) {
    if (shipment.sendingCompany.id === authStore.companyId) return true
    if (shipment.shipperCompany?.id === authStore.companyId) return true
    return false
}

function updateShipment(shipment: MinimalShipment) {
    selectedShipment.value = shipment
    shipmentModalOpen.value = true
}

function handleShipment(shipment: MinimalShipment) {
    selectedShipment.value = shipment
    handleShipmentModalOpen.value = true
}

function createTicket(shipment: MinimalShipment) {
    selectedShipment.value = shipment
    ticketModalOpen.value = true
}

async function ticketModalClosed() {
    ticketModalOpen.value = false
    selectedShipment.value = undefined
}

function showCMR(shipment: MinimalShipment) {
    selectedShipment.value = shipment
    cmrModalOpen.value = true
}

async function shipmentModalClosed() {
    shipmentModalOpen.value = false
    selectedShipment.value = undefined
}

async function handleShipmentModalClosed() {
    handleShipmentModalOpen.value = false
    selectedShipment.value = undefined
}

async function cmrModalClosed() {
    cmrModalOpen.value = false
    selectedShipment.value = undefined
}

function setFilter(type: string | number | null, filterName: string) {
    params.value.filter[filterName] = type
    params.value.page = 1
}

function resetTour() {
    params.value.filter['tour-id'] = undefined
    tour.value = undefined
}

function clearDates() {
    datepicker.value?.closeMenu()
    date.value = []
    delete params.value.filter['start-date']
    delete params.value.filter['end-date']
}

function showShipment(id: string): RouteLocationRaw {
    if (route.name?.toString()?.includes('customers.show')) {
        return {
            name: 'dm.customers.shipments.show',
            params: { ...route.params, shipmentId: id },
            query: route.query,
        }
    }

    return {
        name: 'dm.shipments.show',
        params: { shipmentId: id },
        query: route.query,
    }
}

onBeforeMount(() => {
    if (params.value.filter['hub-id']) hubs.fetch()
})

watch(date, () => {
    if (!date.value[0] || !date.value[1]) return
    setFilter(dayjs(date.value[0]).startOf('day').toISOString(), 'start-date')
    setFilter(dayjs(date.value[1]).endOf('day').toISOString(), 'end-date')
})

watch(
    () => [params.value.filter['tour-id']],
    () => {
        if (!params.value.filter['tour-id']) {
            tour.value = undefined
        } else {
            fetchTour(params.value.filter['tour-id'])
        }
    },
)

useMittListener('fetchShipments', () => refetch())
</script>

<template>
    <MyTable
        table-id="dm-shipments"
        :rows="data"
        :pagination-data="paginationData"
        :loading="loading"
        :error="error"
        :get-data="refetch"
        :entity-name="t('shipments')"
        :has-time-range="!!params.filter['start-date']"
    >
        <template #filters>
            <Datepicker
                ref="datepicker"
                v-model="date"
                input-class-name="w-64"
                range
                :enable-time-picker="false"
                auto-apply
                :teleport="true"
                :clearable="false"
                :preset-dates="presetRanges"
                close-on-scroll
                position="left"
                format="yyyy-MM-dd"
                :transitions="false"
                :placeholder="t('allTime')"
                :config="{ closeOnAutoApply: true }"
            >
                <template #allTime>
                    <button
                        class="dp__btn dp--preset-range"
                        @click="clearDates()"
                        v-text="t('allTime')"
                    />
                </template>
            </Datepicker>

            <MyFilterDivider />

            <MyFilterButton
                filter-name="type"
                :value="null"
                default
                @selected="setFilter"
                v-text="t('all')"
            />

            <MyFilterButton
                filter-name="type"
                :value="ShipmentType.Delivery.toString()"
                @selected="setFilter"
                v-text="t('delivery')"
            />

            <MyFilterButton
                filter-name="type"
                :value="ShipmentType.Return.toString()"
                @selected="setFilter"
                v-text="t('return')"
            />

            <MyFilterButton
                filter-name="type"
                :value="ShipmentType.Pickup.toString()"
                @selected="setFilter"
                v-text="t('pickup')"
            />

            <MyFilterDivider />

            <MyFilterButton
                v-if="tour"
                class="!bg-primary-200"
                :value="null"
                filter-name="tour-id"
                @selected="resetTour"
                v-text="tour.name"
            />

            <MyFilterSelect
                :label="t('hubs')"
                :options="hubs.items.value"
                :loading="hubs.fetching.value"
                filter-name="hub-id"
                @focus="hubs.fetch()"
                @change="setFilter"
            />

            <MyFilterSelect
                :label="t('locations')"
                :options="locations"
                filter-name="sending-location-id"
                @change="setFilter"
            />

            <MyFilterDivider />

            <MyFilterSelect
                :label="t('status')"
                :options="shipmentStatuses"
                :dropdown-width="240"
                filter-name="status"
                @change="setFilter"
            />
        </template>

        <template #actionRow="{ row }">
            <RouterLink :to="showShipment(row.id)">
                <ActionRowItem>
                    <mdi:eye />
                </ActionRowItem>
            </RouterLink>
        </template>

        <template #actionMenu="{ row }">
            <!-- Disabled for now since it doesn't work -->
            <ActionMenuItem v-if="row.id === 'f'" @click="showCMR(row)">
                <span class="mr-2"><mdi:document /></span>
                {{ t('showCmr') }}
            </ActionMenuItem>

            <ActionMenuItem
                v-if="row.shipperCompany && isSenderOrShipper(row)"
                @click="createTicket(row)"
            >
                <span class="mr-2"><mdi:briefcase-edit /></span>
                {{ t('createEntity', { entity: t('ticket') }) }}
            </ActionMenuItem>

            <template v-if="isSenderOrShipper(row)">
                <ActionMenuItem @click="updateShipment(row)">
                    <span class="mr-2"><mdi:pencil /></span>
                    {{ t('edit') }}
                </ActionMenuItem>

                <ActionMenuItem v-if="!row.handledAt" @click="handleShipment(row)">
                    <mdi:package-variant-closed-check class="mr-2" />
                    {{
                        row.type === ShipmentType.Delivery
                            ? t('markAsDelivered')
                            : t('markAsPickedUp')
                    }}
                </ActionMenuItem>
            </template>
        </template>

        <MyTableColumn :name="t('shipmentNumber')" property="shipmentNumber" />
        <MyTableColumn :name="t('name')" property="name" />
        <MyTableColumn :name="t('timeslot')" property="timeslot" />
        <template #timeslot="{ row }">
            <div>
                {{ row.handleableAfter ? localeTime(row.handleableAfter) : null }}
                <span v-if="row.handleableAfter && row.handleableBefore" v-text="'-'" />
                {{ row.handleableBefore ? localeTime(row.handleableBefore) : null }}
            </div>
        </template>

        <MyTableColumn :name="t('receiver')" property="receivingCompany.name" />
        <template #[`receivingCompany.name`]="{ row }">
            <RouterLink
                v-if="row.receivingCustomerId"
                :to="{
                    name: 'dm.customers.show',
                    params: { id: row.receivingCustomerId as string },
                }"
            >
                <div class="flex">
                    {{ row.receivingCompany.name }}
                    <div v-if="row.soldTo" class="ml-1">
                        <span v-text="'-'" />
                        {{ row.soldTo }}
                    </div>
                </div>
            </RouterLink>
            <div v-else class="flex">
                {{ row.receivingCompany.name }}
                <div v-if="row.soldTo" class="ml-1">
                    <span v-text="'-'" />
                    {{ row.soldTo }}
                </div>
            </div>
        </template>
        <MyTableColumn :name="t('receiverLocation')" property="receivingLocation.name" />
        <template #[`receivingLocation.name`]="{ row }">
            <div class="flex">
                {{ row.receivingLocation.name }}
                <div v-if="row.shipTo" class="ml-1">
                    <span v-text="'-'" />
                    {{ row.shipTo }}
                </div>
            </div>
        </template>
        <MyTableColumn :name="t('receiverAddress')" property="address" />
        <template #address="{ row }">
            {{ row.address ?? row.receivingLocation.address }}
        </template>
        <MyTableColumn :name="t('tour')" property="tour.name" :sortable="false" />
        <MyTableColumn :name="t('plannedAt')" property="plannedAt" />
        <template #plannedAt="{ row }">{{ localeDate(row.plannedAt) }}</template>
        <MyTableColumn :name="t('status')" property="status" :sortable="false" />
        <template #status="{ row }">
            <div class="flex items-center">
                <ShipmentStatus class="truncate" :shipment="row" />
            </div>
        </template>
        <MyTableColumn :name="t('handledAt')" property="handledAt" />
        <template #handledAt="{ row }">
            {{ row.handledAt ? datetime(row.handledAt) : null }}
        </template>
        <MyTableColumn :name="t('deviations')" property="deviationsCount" />
        <template #deviationsCount="{ row }">
            <div class="flex items-center space-x-2">
                <span v-text="row.deviationsCount" />
                <mdi:alert v-if="row.deviationsCount > 0" class="text-yellow-500" />
            </div>
        </template>
        <MyTableColumn :name="t('tickets')" property="ticketsCount" />
        <template #ticketsCount="{ row }">
            <div class="flex items-center space-x-2">
                <span v-text="row.ticketsCount" />
                <mdi:alert v-if="row.ticketsCount > 0" class="text-yellow-500" />
            </div>
        </template>

        <MyTableColumn :name="t('collis')" property="collisCount" />
        <template #collisCount="{ row }">
            {{ row.collisCount }}
        </template>

        <MyTableColumn :name="t('sendingLocation')" property="sendingLocation.name" hidden />
        <MyTableColumn :name="t('sender')" property="sendingCompany.name" hidden />
        <MyTableColumn :name="t('shippingLocation')" property="shipperLocation.name" hidden />
        <MyTableColumn :name="t('shipper')" property="shipperCompany.name" hidden />
        <MyTableColumn :name="t('haulierLocation')" property="haulierLocation.name" hidden />
        <MyTableColumn :name="t('haulier')" property="haulierCompany.name" hidden />
        <MyTableColumn :name="t('type')" property="type" hidden />
        <template #type="{ row }">
            {{ t(shipmentTypes[row.type]) }}
        </template>
        <MyTableColumn :name="t('transactions')" property="transactions" :sortable="false" />
        <template #transactions="{ row }">
            <div class="space-x-1 flex">
                <div v-for="transaction in row.transactions" :key="transaction.id">
                    <RouterLink
                        v-tooltip="
                            t('packagingTransaction') + '\n' + datetime(transaction.confirmedAt)
                        "
                        :to="{
                            name: 'packaging.transactions.show',
                            params: { transactionId: transaction.id },
                        }"
                    >
                        <mdi:package-variant-closed class="h-4 w-4" />
                    </RouterLink>
                </div>
            </div>
        </template>

        <MyTableColumn :name="t('category')" property="category.name" hidden />
        <MyTableColumn :name="t('deliveredBy')" property="deliveredByUser.name" hidden />
        <MyTableColumn :name="t('deliveryNote')" property="deliveryNote" hidden />
        <MyTableColumn :name="t('references')" property="references" hidden />
        <MyTableColumn :name="t('receiverName')" property="receiverName" hidden />
        <template #noDataButton>
            <MyButton
                v-if="params.filter['start-date']"
                plain
                scheme="light"
                size="small"
                @click="clearDates()"
            >
                <mdi:magnify class="mr-2 h-4 w-4" />
                {{ t('searchAllTime') }}
            </MyButton>
        </template>
    </MyTable>

    <ManageShipmentModal
        v-model="shipmentModalOpen"
        :shipment-id="selectedShipment?.id"
        @close="shipmentModalClosed"
        @saved="refetch()"
    />

    <HandleShipmentModal
        v-if="selectedShipment"
        v-model="handleShipmentModalOpen"
        :shipment-id="selectedShipment.id"
        :shipment-type="selectedShipment.type"
        @close="handleShipmentModalClosed"
        @saved="refetch()"
    />

    <CreateTicketModal
        v-if="selectedShipment"
        v-model="ticketModalOpen"
        :shipment="selectedShipment"
        @close="ticketModalClosed"
    />

    <CMR
        v-if="selectedShipment"
        :id="selectedShipment.id"
        v-model:open="cmrModalOpen"
        @close="cmrModalClosed"
    />
</template>
