import { ref, Ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import { ColumnFilter, TableColumn } from '@/types/table'

export default function useColumnFiltering() {
    const route = useRoute()

    let parsedColumnFilters = {}
    if (route.query.columnFilters) {
        parsedColumnFilters = JSON.parse(route.query.columnFilters.toString())
    }

    const filters: Ref<Record<string, ColumnFilter[]>> = ref(parsedColumnFilters)

    const filtersAsQueryString = computed(() => {
        const valid: Record<string, ColumnFilter[]> = {}

        for (const [property, columnFilters] of Object.entries(filters.value)) {
            const nonEmptyFilters = columnFilters.filter((filter) => filter.value.trim().length > 0)
            if (nonEmptyFilters.length > 0) {
                valid[property] = nonEmptyFilters
            }
        }
        if (Object.keys(valid).length === 0) {
            return undefined
        }
        return JSON.stringify(valid)
    })
    return {
        filters,
        filtersAsQueryString,
        addFilter(columnProperty: string) {
            if (!filters.value[columnProperty]) {
                filters.value[columnProperty] = [{ value: '', type: 0 }]
                return
            }
            filters.value[columnProperty].push({ value: '', type: 0 })
        },
        removeFilter(column: TableColumn, itemIndex: number) {
            if (!filters.value[column.property]) return
            filters.value[column.property].splice(itemIndex, 1)
        },
        clearFilters(columnProperty: string) {
            filters.value[columnProperty] = [{ value: '', type: 0 }]
        },
        clearAllFilters() {
            filters.value = {}
        },
    }
}
