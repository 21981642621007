import { Except } from 'type-fest'

import {
    CustomerLocation,
    FabricDrawing,
    IncludedCompany,
    Location,
    LocationPoint,
} from '@/types/company'
import { FieldPreference, FileAttachment, Model, uuid } from '@/types/general'
import { MinimalUser } from '@/types/user'
import { TransportObjectType } from '@/types/transport-object'
import { MinimalTourReport } from '@/types/damage-report'
import { TourType } from '@/types/driver-report'
import { TransactionProduct } from '@/types/transaction'

export interface Hub extends Model {
    id: uuid
    company: IncludedCompany
    shipperCompany: IncludedCompany | null
    shipperLocation: Location | null
    name: string
    hauliers: { id: uuid; name: string }[]
    toursCount: number
    deviationsCount: number
    createdAt: string
    updatedAt: string
    deletedAt: string | null
}

export interface MinimalHub extends Model {
    id: uuid
    company: IncludedCompany
    shipperCompany: IncludedCompany | null
    name: string
    toursCount: number
    deviationsCount: number
    ticketsCount: number
    onTimeDeliveriesCount: number
    deliveredShipmentsCount: number
    shipmentsCount: number
    delayedShipmentsCount: number
    handledEarlyShipmentsCount: number
    hauliers: { id: uuid; name: string }[]
    createdAt: string
    updatedAt: string
    deletedAt: string | null
}

export interface Tour extends Model {
    id: uuid
    hub: MinimalHub | null
    events: TourEvent[]
    deviations: IncludedDeviation[]
    tickets: IncludedTicket[]
    images: FileAttachment[]
    company: IncludedCompany
    shipperCompany: IncludedCompany | null
    haulierCompany: IncludedCompany | null
    driver: MinimalUser | null
    transportObjects: { id: uuid; type: TransportObjectType; registrationNumber: string }[]
    reports: MinimalTourReport[]
    driverReports: {
        id: uuid
        startedAt: string | null
    }[]
    categories: string | null
    name: string
    type: TourType
    state: TourState
    haulierCanManage: boolean
    plannedAt: string
    startedAt: string | null
    endedAt: string | null
    startLocation: LocationPoint | null
    endLocation: LocationPoint | null
    createdAt: string
    updatedAt: string
    deletedAt: string | null
    optimizationDetails: { duration: number; distance: number } | null
}

export interface MinimalTour extends Model {
    id: uuid
    company: IncludedCompany
    shipperCompany: IncludedCompany | null
    haulierCompany: IncludedCompany | null
    hub: MinimalHub | null
    driver: MinimalUser | null
    transportObjects: { id: uuid; type: TransportObjectType; registrationNumber: string }[]
    shipmentsCount: number
    deliveredShipmentsCount: number
    delayedShipmentsCount: number
    handledEarlyShipmentsCount: number
    deviationsCount: number
    ticketsCount: number
    categories: string | null
    name: string
    plannedAt: string
    type: TourType
    state: TourState
    haulierCanManage: boolean
    startedAt: string | null
    endedAt: string | null
    createdAt: string
    optimizationDetails: { duration: number; distance: number } | null
}
export interface TourEvent extends Model {
    id: uuid
    tourId: uuid
    transactions: { id: uuid; confirmedAt: string }[]
    startLocation: LocationPoint
    endLocation: LocationPoint | null
    deliveryLocation: LocationPoint | null
    order: number
    plannedDuration: number
    estimatedAt: string | null
    createdAt: string
    updatedAt: string
    shipment: MinimalShipment | null
    type: EventType
    handledAt: string | null
}

export interface Deviation extends Model {
    id: uuid
    type: DeviationType
    ticketId: uuid | null
    state: DeviationState
    note: string | null
    collis: uuid[]
    colliNumbers: number[]
    createdAt: string
    user: MinimalUser | null
    logs: DeviationLog[]
    images: string[]
    tags: MinimalCompanyTag[]
    tour: Tour // TODO: Fix type
    shipment: MinimalShipment | null
    location: LocationPoint | null
    number: string | null
}

export interface Ticket extends Model {
    id: uuid
    company: IncludedCompany
    companyUser: MinimalUser | null
    client: IncludedCompany
    clientUser: MinimalUser | null
    responsibleCompanyId: uuid | null
    tour: MinimalDeviationTour | null
    shipment: MinimalShipment | null
    deviation: Deviation | null
    tags: MinimalCompanyTag[]
    logs: DeviationLog[]
    state: DeviationState
    number: string | null
    createdAt: string
    updatedAt: string
    location: LocationPoint | null
    isSubcribed: boolean
}

export interface DeviationLog extends Model {
    id: uuid
    type: DeviationLogType
    note: string | null
    user: MinimalUser
    createdAt: string
    files: FileAttachment[]
}

export enum DeviationLogType {
    Created = 0,
    Solved = 1,
    Closed = 2,
    Escalated = 3,
    AssignedToUser = 4,
    Comment = 5,
    Reopened = 6,
    UpdatedTags = 7,
}

export enum DeviationState {
    Open = 0,
    Closed = 1,
    Solved = 2,
}

export enum DeviationType {
    Load = 0,
    Delivery = 1,
    Pickup = 2,
    Unload = 3,
}

export enum ColliScanEventType {
    Load = 0,
    Delivery = 1,
    Pickup = 2,
    Unload = 3,
}

export interface FormTourEvent {
    id: uuid
    order: number
    shipment: MinimalShipment | null
    type: EventType
    startLocation: LocationPoint
}

export interface FormShipmentColli {
    colli: Colli
    amount: number
}

export interface Category extends Model {
    id: uuid
    company: IncludedCompany
    name: string
    createdAt: string
    updatedAt: string
    deletedAt: string | null
}

export interface Colli extends Model {
    id: uuid
    company: IncludedCompany
    scanEvents: {
        id: uuid
        type: ColliScanEventType
        createdAt: string
    }[]
    tickets: uuid[]
    shipment: {
        id: uuid
        shipmentNumber: string
        soldTo: string
        shipTo: string
        references: string
    } | null
    tour: { id: uuid; name: string } | null
    name: string
    colliNumber: string
    volume: number
    width: number
    length: number
    height: number
    weight: number
    description: string | null
    pickupDate: string | null
    deliveryDate: string | null
    createdAt: string
    updatedAt: string
    deletedAt: string | null
}

export interface MinimalShipment extends Model {
    id: uuid
    sendingCompany: IncludedCompany
    sendingLocation: Location
    receivingCompany: IncludedCompany
    receivingLocation: Location
    receivingCustomerId: uuid | null
    shipperCompany: IncludedCompany | null
    shipperLocation: Location | null
    haulierCompany: IncludedCompany | null
    haulierLocation: Location | null
    deliveredByUser: MinimalUser | null
    soldTo: string | null
    shipTo: string | null
    type: ShipmentType
    shipmentNumber: string | null
    tour: { id: uuid; name: string } | null
    transactions: {
        id: uuid
        confirmedAt: string
        // Products are only included for the shipments in the BookingModal
        products?: TransactionProduct[]
    }[]
    images: FileAttachment[]
    palletExchange: boolean
    deliveryNote: string | null
    receiverSignature: string | null
    deviationsCount: number
    ticketsCount: number
    collisCount: number
    tourEventCount: number
    plannedAt: string
    handledAt: string | null
    handleableAfter: string | null
    handleableBefore: string | null
    unsuccessful: boolean
    delayed: boolean
    handledTooEarly: boolean
    createdAt: string
    updatedAt: string
    deletedAt: string | null
    receiverName: string | null
    references: string | null
    name: string | null
    category: Category | null
    address: string | null
    addressLocation: LocationPoint | null
    currentEventType: ShipmentEventType | null
}

export interface ShipmentColli extends Colli {
    amount: number
}

export interface Shipment extends Model {
    id: uuid
    sendingCompany: IncludedCompany
    sendingLocation: Location
    receivingCompany: IncludedCompany
    receivingCustomerId: uuid | null
    receivingLocation: Location
    shipperCompany: IncludedCompany | null
    shipperLocation: Location | null
    haulierCompany: IncludedCompany | null
    haulierLocation: Location | null
    deliveredByUser: MinimalUser | null
    type: ShipmentType
    tour: {
        id: uuid
        name: string
    } | null
    tourEvent: TourEvent | null
    images: FileAttachment[]
    deviations: IncludedDeviation[]
    tickets: IncludedTicket[]
    category: Category | null
    palletExchange: boolean
    deliveryNote: string | null
    plannedAt: string
    handledAt: string | null
    createdAt: string
    updatedAt: string
    deletedAt: string | null
    handleableAfter: string | null
    handleableBefore: string | null
    unsuccessful: boolean
    delayed: boolean
    handledTooEarly: boolean
    receiverName: string | null
    receiverSignature: string | null
    collis: ShipmentColli[]
    soldTo: string | null
    shipTo: string | null
    shipmentNumber: string | null
    references: string | null
    name: string | null
    address: string | null
    addressLocation: LocationPoint | null
    events: ShipmentEvent[]
    deliveryDescriptionId: uuid | null
    currentEventType: ShipmentEventType | null
    podReceiptId: uuid | null
}

export interface ShipmentEvent {
    id: uuid
    user: MinimalUser
    type: ShipmentEventType
    location: LocationPoint | null
    note: string | null
    signatureName: string | null
    signaturePath: string | null
    webHandled: boolean
    transpiredAt: string
    createdAt?: string
}

export interface IncludedDeviation extends Model {
    id: uuid
    tourId: uuid
    shipmentId: uuid | null
    type: DeviationType
    ticketId: uuid | null
    userId: uuid | null
    state: DeviationState
    tags: { id: uuid; name: string }[]
}

export interface IncludedTicket extends Model {
    id: uuid
    companyId: uuid
    companyUserId: uuid | null
    clientId: uuid
    clientUserId: uuid | null
    tourId: uuid | null
    shipmentId: uuid | null
    deviationId: uuid | null
    state: DeviationState
    tags: { id: uuid; name: string }[]
}

export interface MinimalDeviation extends Model {
    id: uuid
    type: DeviationType
    ticketId: uuid | null
    state: DeviationState
    number: string | null
    note: string | null
    collis: string[] | null
    user: MinimalUser | null
    tour: MinimalDeviationTour
    shipment: MinimalShipment | null
    tags: { id: uuid; name: string }[]
    createdAt: string
    updatedAt: string
}

export interface MinimalTicket extends Model {
    id: uuid
    company: IncludedCompany
    companyUser: MinimalUser | null
    client: IncludedCompany
    clientUser: MinimalUser | null
    responsibleCompanyId: uuid | null
    tour: MinimalDeviationTour | null
    shipment: MinimalShipment | null
    tags: { id: uuid; name: string }[]
    state: DeviationState
    number: string | null
    createdAt: string
    updatedAt: string
}

export interface MinimalDeviationTour {
    id: uuid
    name: string
    hub: { id: uuid; name: string } | null
    driver: MinimalUser | null
    company: IncludedCompany
    haulierCompany: IncludedCompany | null
    shipperCompany: IncludedCompany | null
}

export interface MinimalDeviationShipment {
    id: uuid
    shipmentNumber: string
    receiverCompany: IncludedCompany
}

export interface MinimalCompanyTag {
    id: uuid
    companyId: uuid
    name: string
}

export interface DeliveryDescription extends Model {
    id: uuid
    fields: Record<string, string | null>
    createdAt: string
    updatedAt: string
    drawingData: FabricDrawing | null
    drawingPath: string | null
    images: FileAttachment[]
    dropNumber: string | null
    address: string | null
    location: LocationPoint | null
    locations: CustomerLocation[]
}

export enum TourState {
    Created = 0,
    EnRoute = 1,
    Finished = 2,
}

export enum ShipmentType {
    Delivery = 0,
    Return = 1,
    Pickup = 2,
}

export enum EventType {
    Shipment = 0,
    Ferry = 1,
    Bridge = 2,
}

export enum TourImageType {
    TourStart = 0,
    TourEnd = 1,
}

export enum ShipmentEventType {
    TerminalLoad = 0,
    TerminalUnload = 1,
    Delivery = 2,
    Pickup = 3,
}

export interface DeliveryManagementPreferences {
    shipmentPodCreation: boolean
    colliScan: FieldPreference
    images: FieldPreference
    note: FieldPreference
    transaction: FieldPreference
    signature: FieldPreference
    unsuccessful: FieldPreference
    deviation: FieldPreference
    hideReturnShipmentColliScan: boolean
}

export interface MinimalBooking extends Model {
    id: uuid
    bookingNumber: string
    company: { id: uuid; name: string }
    customerCompany: { id: uuid; name: string }
    shipments: { id: uuid; shipmentNumber: string }[]
    createdAt: string
    updatedAt: string
}

export interface Booking extends Except<MinimalBooking, 'customerCompany' | 'shipments'> {
    customerCompany: IncludedCompany
    shipments: MinimalShipment[]
}
