<script lang="ts" setup>
import type { HTMLClass } from '@/types/inputs'

import { computed, getCurrentInstance, inject, onBeforeUnmount, onMounted, watch } from 'vue'

import {
    insertColumnKey,
    InternalTableColumn,
    removeColumnKey,
    updateColumnKey,
} from '@/types/table'

export interface Props {
    property: string
    name: string
    date?: string
    hidden?: boolean
    sortable?: boolean
    draggable?: boolean
    group?: string
    minWidth?: number
    width?: number
    booleanColumn?: boolean
    class?: HTMLClass
    filterable?: boolean
}

const instance = getCurrentInstance()
const props = withDefaults(defineProps<Props>(), {
    hidden: false,
    sortable: true,
    draggable: true,
    minWidth: 100,
})

const column = computed<InternalTableColumn>(() => ({
    property: props.property,
    name: props.name,
    date: props.date,
    hidden: props.hidden,
    sortable: props.sortable,
    draggable: props.draggable,
    group: props.group,
    minWidth: Math.min(props.minWidth, props.width || Infinity),
    width: props.width || (props.booleanColumn ? 120 : undefined),
    slots: instance?.slots,
    order: 0,
    booleanColumn: props.booleanColumn || false,
    class: props.class,
    filterable: props.filterable,
}))

const insertColumn = inject(insertColumnKey)!
const updateColumn = inject(updateColumnKey)!
const removeColumn = inject(removeColumnKey)!

watch(column, () => updateColumn(column.value.property, column.value))
onMounted(() => insertColumn!(column.value))
onBeforeUnmount(() => removeColumn(column.value))
</script>

<template>
    <slot />
</template>
